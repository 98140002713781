import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import i18n from '@/plugins/i18n'
import { createVuetify } from 'vuetify'
import { pt, en, es } from 'vuetify/locale'

export default createVuetify({
  locale: {
    messages: {
      pt: pt,
      en: en,
      es: es,
    },
    locale: i18n.global.locale.value,
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      // light: {
      //   colors: {
      //     primary: '#00ADB5',
      //     secondary: '#46CDCF',
      //     'on-surface': '#222831',
      //   },
      // },
    },
  },
})
