<template>
  <div>
    <v-menu>
      <template #activator="{ props }">
        <v-btn v-bind="props" text elevation="0" class="btn-lang">
          <div>
            <img v-if="selectedFlag" :src="flags[selectedFlag]" class="flag-icon" />
            <span v-else>{{ selectedFlag }}</span>
            <span v-if="selectedFlag" class="align-icon-with-text pl-3 text-uppercase">{{ selectedFlag }}</span>
          </div>
          <v-icon class="ml-2">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list class="d-flex flex-column align-center">
        <v-list-item v-for="(flag, code) in flags" :key="code" class="hover-item" @click="changeLocale(code)">
          <img :src="flag" class="flag-icon" />
          <span class="align-icon-with-text pl-3 text-uppercase">{{ code }}</span>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
    </v-menu>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { setDefaultOptions } from 'date-fns'
import { ptBR, enUS, es } from 'date-fns/locale'
import { useAppStore } from '@/stores/app'
import { useLocale } from 'vuetify'

const { t, locale } = useI18n({ useScope: 'global', inheritLocale: true })
const { current } = useLocale()
const appStore = useAppStore()

const flags = ref({
  pt: 'https://flagicons.lipis.dev/flags/4x3/br.svg',
  en: 'https://flagicons.lipis.dev/flags/4x3/us.svg',
  es: 'https://flagicons.lipis.dev/flags/4x3/es.svg',
})
const selectedFlag = ref(appStore.lang || locale.value)
const emit = defineEmits(['change'])

const changeLocale = (code) => {
  locale.value = code
  selectedFlag.value = code
  appStore.lang = code

  const languageOptions = {
    pt: ptBR,
    es: es,
    en: enUS,
  }

  setDefaultOptions({
    locale: languageOptions[code],
  })

  current.value = code
  emit('change')
}
</script>
<style scoped>
.flag-icon {
  height: 16px;
}
.hover-item {
  cursor: pointer;
}
.hover-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.btn-lang {
  background-color: transparent !important;
  color: rgb(155, 155, 155) bold !important;
}
</style>
