import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'
import { setDefaultOptions, Locale } from 'date-fns'
import { ptBR, enUS, es } from 'date-fns/locale'

export const DEFAULT_LANGUAGE = 'pt'
export const FALLBACK_LANGUAGE = 'en'
export const BROWSER_LANGUAGE = navigator?.language?.split('-')[0]

const languageOptions: { [key: string]: Locale } = {
  pt: ptBR,
  es: es,
  en: enUS,
}

const savedState = localStorage.getItem('app')
const state = savedState ? JSON.parse(savedState) : { lang: DEFAULT_LANGUAGE }
const currentLang = state.lang || DEFAULT_LANGUAGE

setDefaultOptions({
  locale: languageOptions[currentLang] || enUS, // Definir o idioma padrão como fallback
})

const i18n = createI18n({
  locale: currentLang,
  fallbackLocale: FALLBACK_LANGUAGE,
  legacy: false,
  globalInjection: true,
  messages,
})

export default i18n
