/* tslint:disable */
/* eslint-disable */
/**
 * Portal Telemedicina API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AnalystUpdateViewModel } from '../models';
// @ts-ignore
import { AnalystViewModel } from '../models';
// @ts-ignore
import { AtenaViewModel } from '../models';
// @ts-ignore
import { AuthViewModel } from '../models';
// @ts-ignore
import { InlineObject } from '../models';
// @ts-ignore
import { InlineObject1 } from '../models';
// @ts-ignore
import { InlineResponse200 } from '../models';
// @ts-ignore
import { InlineResponse2001 } from '../models';
// @ts-ignore
import { Notification } from '../models';
// @ts-ignore
import { PhysicianUpdateViewModel } from '../models';
// @ts-ignore
import { PhysicianViewModel } from '../models';
// @ts-ignore
import { PutRequestBody } from '../models';
// @ts-ignore
import { Role } from '../models';
// @ts-ignore
import { SocketUsersViewModel } from '../models';
/**
 * AtenaApi - axios parameter creator
 * @export
 */
export const AtenaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AnalystViewModel} [analystViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorAnalystPost: async (xAuth?: string, analystViewModel?: AnalystViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Analyst`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analystViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AnalystUpdateViewModel} [analystUpdateViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorAnalystPut: async (xAuth?: string, analystUpdateViewModel?: AnalystUpdateViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Analyst`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analystUpdateViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [objectId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorAtenaGet: async (objectId?: string, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Atena`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (objectId !== undefined) {
                localVarQueryParameter['objectId'] = objectId;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AtenaViewModel} [atenaViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorAtenaPost: async (xAuth?: string, atenaViewModel?: AtenaViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Atena`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(atenaViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AtenaViewModel} [atenaViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorAtenaPut: async (xAuth?: string, atenaViewModel?: AtenaViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Atena`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(atenaViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [role] 
         * @param {string} [name] 
         * @param {number} [source] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorAtenaUsersGet: async (page?: number, pageSize?: number, role?: string, name?: string, source?: number, notifications?: Array<Notification>, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Atena/Users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (role !== undefined) {
                localVarQueryParameter['Role'] = role;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (source !== undefined) {
                localVarQueryParameter['Source'] = source;
            }

            if (notifications) {
                localVarQueryParameter['Notifications'] = notifications;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {PhysicianViewModel} [physicianViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorPhysicianPost: async (xAuth?: string, physicianViewModel?: PhysicianViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Physician`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(physicianViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {PhysicianUpdateViewModel} [physicianUpdateViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorPhysicianPut: async (xAuth?: string, physicianUpdateViewModel?: PhysicianUpdateViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/Physician`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(physicianUpdateViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAtenaAdminAppDetailsIdGet: async (id: string, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAtenaAdminAppDetailsIdGet', 'id', id)
            const localVarPath = `/api/atena/admin/app/details/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAtenaAdminAppDetailsPut: async (xAuth?: string, inlineObject1?: InlineObject1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/atena/admin/app/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [role] 
         * @param {string} [name] 
         * @param {number} [source] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAtenaAdminAppGet: async (page?: number, pageSize?: number, role?: string, name?: string, source?: number, notifications?: Array<Notification>, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/atena/admin/app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (role !== undefined) {
                localVarQueryParameter['Role'] = role;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (source !== undefined) {
                localVarQueryParameter['Source'] = source;
            }

            if (notifications) {
                localVarQueryParameter['Notifications'] = notifications;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAtenaAdminAppPost: async (xAuth?: string, inlineObject?: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/atena/admin/app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {PutRequestBody} [putRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAtenaAdminAppPut: async (xAuth?: string, putRequestBody?: PutRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/atena/admin/app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAtenaAdminRoleGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/atena/admin/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {SocketUsersViewModel} [socketUsersViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorUsersOnlinePost: async (xAuth?: string, socketUsersViewModel?: SocketUsersViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/Administrator/Users/Online`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(socketUsersViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AuthViewModel} [authViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticatePost: async (xAuth?: string, authViewModel?: AuthViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AtenaApi - functional programming interface
 * @export
 */
export const AtenaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AtenaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AnalystViewModel} [analystViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorAnalystPost(xAuth?: string, analystViewModel?: AnalystViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorAnalystPost(xAuth, analystViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AnalystUpdateViewModel} [analystUpdateViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorAnalystPut(xAuth?: string, analystUpdateViewModel?: AnalystUpdateViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorAnalystPut(xAuth, analystUpdateViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [objectId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorAtenaGet(objectId?: string, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorAtenaGet(objectId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AtenaViewModel} [atenaViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorAtenaPost(xAuth?: string, atenaViewModel?: AtenaViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorAtenaPost(xAuth, atenaViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AtenaViewModel} [atenaViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorAtenaPut(xAuth?: string, atenaViewModel?: AtenaViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorAtenaPut(xAuth, atenaViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [role] 
         * @param {string} [name] 
         * @param {number} [source] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorAtenaUsersGet(page?: number, pageSize?: number, role?: string, name?: string, source?: number, notifications?: Array<Notification>, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorAtenaUsersGet(page, pageSize, role, name, source, notifications, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {PhysicianViewModel} [physicianViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorPhysicianPost(xAuth?: string, physicianViewModel?: PhysicianViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorPhysicianPost(xAuth, physicianViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {PhysicianUpdateViewModel} [physicianUpdateViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorPhysicianPut(xAuth?: string, physicianUpdateViewModel?: PhysicianUpdateViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorPhysicianPut(xAuth, physicianUpdateViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAtenaAdminAppDetailsIdGet(id: string, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAtenaAdminAppDetailsIdGet(id, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAtenaAdminAppDetailsPut(xAuth?: string, inlineObject1?: InlineObject1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAtenaAdminAppDetailsPut(xAuth, inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [role] 
         * @param {string} [name] 
         * @param {number} [source] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAtenaAdminAppGet(page?: number, pageSize?: number, role?: string, name?: string, source?: number, notifications?: Array<Notification>, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAtenaAdminAppGet(page, pageSize, role, name, source, notifications, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAtenaAdminAppPost(xAuth?: string, inlineObject?: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAtenaAdminAppPost(xAuth, inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {PutRequestBody} [putRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAtenaAdminAppPut(xAuth?: string, putRequestBody?: PutRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAtenaAdminAppPut(xAuth, putRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAtenaAdminRoleGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Role>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAtenaAdminRoleGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {SocketUsersViewModel} [socketUsersViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreAdministratorUsersOnlinePost(xAuth?: string, socketUsersViewModel?: SocketUsersViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreAdministratorUsersOnlinePost(xAuth, socketUsersViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AuthViewModel} [authViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticatePost(xAuth?: string, authViewModel?: AuthViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticatePost(xAuth, authViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AtenaApi - factory interface
 * @export
 */
export const AtenaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AtenaApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AnalystViewModel} [analystViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorAnalystPost(xAuth?: string, analystViewModel?: AnalystViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.administratorAnalystPost(xAuth, analystViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AnalystUpdateViewModel} [analystUpdateViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorAnalystPut(xAuth?: string, analystUpdateViewModel?: AnalystUpdateViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.administratorAnalystPut(xAuth, analystUpdateViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [objectId] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorAtenaGet(objectId?: string, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.administratorAtenaGet(objectId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AtenaViewModel} [atenaViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorAtenaPost(xAuth?: string, atenaViewModel?: AtenaViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.administratorAtenaPost(xAuth, atenaViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AtenaViewModel} [atenaViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorAtenaPut(xAuth?: string, atenaViewModel?: AtenaViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.administratorAtenaPut(xAuth, atenaViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [role] 
         * @param {string} [name] 
         * @param {number} [source] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorAtenaUsersGet(page?: number, pageSize?: number, role?: string, name?: string, source?: number, notifications?: Array<Notification>, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.administratorAtenaUsersGet(page, pageSize, role, name, source, notifications, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {PhysicianViewModel} [physicianViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorPhysicianPost(xAuth?: string, physicianViewModel?: PhysicianViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.administratorPhysicianPost(xAuth, physicianViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {PhysicianUpdateViewModel} [physicianUpdateViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorPhysicianPut(xAuth?: string, physicianUpdateViewModel?: PhysicianUpdateViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.administratorPhysicianPut(xAuth, physicianUpdateViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAtenaAdminAppDetailsIdGet(id: string, xAuth?: string, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.apiAtenaAdminAppDetailsIdGet(id, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAtenaAdminAppDetailsPut(xAuth?: string, inlineObject1?: InlineObject1, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.apiAtenaAdminAppDetailsPut(xAuth, inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [role] 
         * @param {string} [name] 
         * @param {number} [source] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAtenaAdminAppGet(page?: number, pageSize?: number, role?: string, name?: string, source?: number, notifications?: Array<Notification>, xAuth?: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.apiAtenaAdminAppGet(page, pageSize, role, name, source, notifications, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAtenaAdminAppPost(xAuth?: string, inlineObject?: InlineObject, options?: any): AxiosPromise<void> {
            return localVarFp.apiAtenaAdminAppPost(xAuth, inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {PutRequestBody} [putRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAtenaAdminAppPut(xAuth?: string, putRequestBody?: PutRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.apiAtenaAdminAppPut(xAuth, putRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAtenaAdminRoleGet(options?: any): AxiosPromise<Array<Role>> {
            return localVarFp.apiAtenaAdminRoleGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {SocketUsersViewModel} [socketUsersViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorUsersOnlinePost(xAuth?: string, socketUsersViewModel?: SocketUsersViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreAdministratorUsersOnlinePost(xAuth, socketUsersViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {AuthViewModel} [authViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticatePost(xAuth?: string, authViewModel?: AuthViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.authenticatePost(xAuth, authViewModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for administratorAnalystPost operation in AtenaApi.
 * @export
 * @interface AtenaApiAdministratorAnalystPostRequest
 */
export interface AtenaApiAdministratorAnalystPostRequest {
    /**
     * 
     * @type {string}
     * @memberof AtenaApiAdministratorAnalystPost
     */
    readonly xAuth?: string

    /**
     * 
     * @type {AnalystViewModel}
     * @memberof AtenaApiAdministratorAnalystPost
     */
    readonly analystViewModel?: AnalystViewModel
}

/**
 * Request parameters for administratorAnalystPut operation in AtenaApi.
 * @export
 * @interface AtenaApiAdministratorAnalystPutRequest
 */
export interface AtenaApiAdministratorAnalystPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AtenaApiAdministratorAnalystPut
     */
    readonly xAuth?: string

    /**
     * 
     * @type {AnalystUpdateViewModel}
     * @memberof AtenaApiAdministratorAnalystPut
     */
    readonly analystUpdateViewModel?: AnalystUpdateViewModel
}

/**
 * Request parameters for administratorAtenaGet operation in AtenaApi.
 * @export
 * @interface AtenaApiAdministratorAtenaGetRequest
 */
export interface AtenaApiAdministratorAtenaGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AtenaApiAdministratorAtenaGet
     */
    readonly objectId?: string

    /**
     * 
     * @type {string}
     * @memberof AtenaApiAdministratorAtenaGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for administratorAtenaPost operation in AtenaApi.
 * @export
 * @interface AtenaApiAdministratorAtenaPostRequest
 */
export interface AtenaApiAdministratorAtenaPostRequest {
    /**
     * 
     * @type {string}
     * @memberof AtenaApiAdministratorAtenaPost
     */
    readonly xAuth?: string

    /**
     * 
     * @type {AtenaViewModel}
     * @memberof AtenaApiAdministratorAtenaPost
     */
    readonly atenaViewModel?: AtenaViewModel
}

/**
 * Request parameters for administratorAtenaPut operation in AtenaApi.
 * @export
 * @interface AtenaApiAdministratorAtenaPutRequest
 */
export interface AtenaApiAdministratorAtenaPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AtenaApiAdministratorAtenaPut
     */
    readonly xAuth?: string

    /**
     * 
     * @type {AtenaViewModel}
     * @memberof AtenaApiAdministratorAtenaPut
     */
    readonly atenaViewModel?: AtenaViewModel
}

/**
 * Request parameters for administratorAtenaUsersGet operation in AtenaApi.
 * @export
 * @interface AtenaApiAdministratorAtenaUsersGetRequest
 */
export interface AtenaApiAdministratorAtenaUsersGetRequest {
    /**
     * 
     * @type {number}
     * @memberof AtenaApiAdministratorAtenaUsersGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AtenaApiAdministratorAtenaUsersGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof AtenaApiAdministratorAtenaUsersGet
     */
    readonly role?: string

    /**
     * 
     * @type {string}
     * @memberof AtenaApiAdministratorAtenaUsersGet
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof AtenaApiAdministratorAtenaUsersGet
     */
    readonly source?: number

    /**
     * 
     * @type {Array<Notification>}
     * @memberof AtenaApiAdministratorAtenaUsersGet
     */
    readonly notifications?: Array<Notification>

    /**
     * 
     * @type {string}
     * @memberof AtenaApiAdministratorAtenaUsersGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for administratorPhysicianPost operation in AtenaApi.
 * @export
 * @interface AtenaApiAdministratorPhysicianPostRequest
 */
export interface AtenaApiAdministratorPhysicianPostRequest {
    /**
     * 
     * @type {string}
     * @memberof AtenaApiAdministratorPhysicianPost
     */
    readonly xAuth?: string

    /**
     * 
     * @type {PhysicianViewModel}
     * @memberof AtenaApiAdministratorPhysicianPost
     */
    readonly physicianViewModel?: PhysicianViewModel
}

/**
 * Request parameters for administratorPhysicianPut operation in AtenaApi.
 * @export
 * @interface AtenaApiAdministratorPhysicianPutRequest
 */
export interface AtenaApiAdministratorPhysicianPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AtenaApiAdministratorPhysicianPut
     */
    readonly xAuth?: string

    /**
     * 
     * @type {PhysicianUpdateViewModel}
     * @memberof AtenaApiAdministratorPhysicianPut
     */
    readonly physicianUpdateViewModel?: PhysicianUpdateViewModel
}

/**
 * Request parameters for apiAtenaAdminAppDetailsIdGet operation in AtenaApi.
 * @export
 * @interface AtenaApiApiAtenaAdminAppDetailsIdGetRequest
 */
export interface AtenaApiApiAtenaAdminAppDetailsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AtenaApiApiAtenaAdminAppDetailsIdGet
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AtenaApiApiAtenaAdminAppDetailsIdGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiAtenaAdminAppDetailsPut operation in AtenaApi.
 * @export
 * @interface AtenaApiApiAtenaAdminAppDetailsPutRequest
 */
export interface AtenaApiApiAtenaAdminAppDetailsPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AtenaApiApiAtenaAdminAppDetailsPut
     */
    readonly xAuth?: string

    /**
     * 
     * @type {InlineObject1}
     * @memberof AtenaApiApiAtenaAdminAppDetailsPut
     */
    readonly inlineObject1?: InlineObject1
}

/**
 * Request parameters for apiAtenaAdminAppGet operation in AtenaApi.
 * @export
 * @interface AtenaApiApiAtenaAdminAppGetRequest
 */
export interface AtenaApiApiAtenaAdminAppGetRequest {
    /**
     * 
     * @type {number}
     * @memberof AtenaApiApiAtenaAdminAppGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AtenaApiApiAtenaAdminAppGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof AtenaApiApiAtenaAdminAppGet
     */
    readonly role?: string

    /**
     * 
     * @type {string}
     * @memberof AtenaApiApiAtenaAdminAppGet
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof AtenaApiApiAtenaAdminAppGet
     */
    readonly source?: number

    /**
     * 
     * @type {Array<Notification>}
     * @memberof AtenaApiApiAtenaAdminAppGet
     */
    readonly notifications?: Array<Notification>

    /**
     * 
     * @type {string}
     * @memberof AtenaApiApiAtenaAdminAppGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiAtenaAdminAppPost operation in AtenaApi.
 * @export
 * @interface AtenaApiApiAtenaAdminAppPostRequest
 */
export interface AtenaApiApiAtenaAdminAppPostRequest {
    /**
     * 
     * @type {string}
     * @memberof AtenaApiApiAtenaAdminAppPost
     */
    readonly xAuth?: string

    /**
     * 
     * @type {InlineObject}
     * @memberof AtenaApiApiAtenaAdminAppPost
     */
    readonly inlineObject?: InlineObject
}

/**
 * Request parameters for apiAtenaAdminAppPut operation in AtenaApi.
 * @export
 * @interface AtenaApiApiAtenaAdminAppPutRequest
 */
export interface AtenaApiApiAtenaAdminAppPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AtenaApiApiAtenaAdminAppPut
     */
    readonly xAuth?: string

    /**
     * 
     * @type {PutRequestBody}
     * @memberof AtenaApiApiAtenaAdminAppPut
     */
    readonly putRequestBody?: PutRequestBody
}

/**
 * Request parameters for apiCoreAdministratorUsersOnlinePost operation in AtenaApi.
 * @export
 * @interface AtenaApiApiCoreAdministratorUsersOnlinePostRequest
 */
export interface AtenaApiApiCoreAdministratorUsersOnlinePostRequest {
    /**
     * 
     * @type {string}
     * @memberof AtenaApiApiCoreAdministratorUsersOnlinePost
     */
    readonly xAuth?: string

    /**
     * 
     * @type {SocketUsersViewModel}
     * @memberof AtenaApiApiCoreAdministratorUsersOnlinePost
     */
    readonly socketUsersViewModel?: SocketUsersViewModel
}

/**
 * Request parameters for authenticatePost operation in AtenaApi.
 * @export
 * @interface AtenaApiAuthenticatePostRequest
 */
export interface AtenaApiAuthenticatePostRequest {
    /**
     * 
     * @type {string}
     * @memberof AtenaApiAuthenticatePost
     */
    readonly xAuth?: string

    /**
     * 
     * @type {AuthViewModel}
     * @memberof AtenaApiAuthenticatePost
     */
    readonly authViewModel?: AuthViewModel
}

/**
 * AtenaApi - object-oriented interface
 * @export
 * @class AtenaApi
 * @extends {BaseAPI}
 */
export class AtenaApi extends BaseAPI {
    /**
     * 
     * @param {AtenaApiAdministratorAnalystPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtenaApi
     */
    public administratorAnalystPost(requestParameters: AtenaApiAdministratorAnalystPostRequest = {}, options?: AxiosRequestConfig) {
        return AtenaApiFp(this.configuration).administratorAnalystPost(requestParameters.xAuth, requestParameters.analystViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AtenaApiAdministratorAnalystPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtenaApi
     */
    public administratorAnalystPut(requestParameters: AtenaApiAdministratorAnalystPutRequest = {}, options?: AxiosRequestConfig) {
        return AtenaApiFp(this.configuration).administratorAnalystPut(requestParameters.xAuth, requestParameters.analystUpdateViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AtenaApiAdministratorAtenaGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtenaApi
     */
    public administratorAtenaGet(requestParameters: AtenaApiAdministratorAtenaGetRequest = {}, options?: AxiosRequestConfig) {
        return AtenaApiFp(this.configuration).administratorAtenaGet(requestParameters.objectId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AtenaApiAdministratorAtenaPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtenaApi
     */
    public administratorAtenaPost(requestParameters: AtenaApiAdministratorAtenaPostRequest = {}, options?: AxiosRequestConfig) {
        return AtenaApiFp(this.configuration).administratorAtenaPost(requestParameters.xAuth, requestParameters.atenaViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AtenaApiAdministratorAtenaPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtenaApi
     */
    public administratorAtenaPut(requestParameters: AtenaApiAdministratorAtenaPutRequest = {}, options?: AxiosRequestConfig) {
        return AtenaApiFp(this.configuration).administratorAtenaPut(requestParameters.xAuth, requestParameters.atenaViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AtenaApiAdministratorAtenaUsersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtenaApi
     */
    public administratorAtenaUsersGet(requestParameters: AtenaApiAdministratorAtenaUsersGetRequest = {}, options?: AxiosRequestConfig) {
        return AtenaApiFp(this.configuration).administratorAtenaUsersGet(requestParameters.page, requestParameters.pageSize, requestParameters.role, requestParameters.name, requestParameters.source, requestParameters.notifications, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AtenaApiAdministratorPhysicianPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtenaApi
     */
    public administratorPhysicianPost(requestParameters: AtenaApiAdministratorPhysicianPostRequest = {}, options?: AxiosRequestConfig) {
        return AtenaApiFp(this.configuration).administratorPhysicianPost(requestParameters.xAuth, requestParameters.physicianViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AtenaApiAdministratorPhysicianPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtenaApi
     */
    public administratorPhysicianPut(requestParameters: AtenaApiAdministratorPhysicianPutRequest = {}, options?: AxiosRequestConfig) {
        return AtenaApiFp(this.configuration).administratorPhysicianPut(requestParameters.xAuth, requestParameters.physicianUpdateViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AtenaApiApiAtenaAdminAppDetailsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtenaApi
     */
    public apiAtenaAdminAppDetailsIdGet(requestParameters: AtenaApiApiAtenaAdminAppDetailsIdGetRequest, options?: AxiosRequestConfig) {
        return AtenaApiFp(this.configuration).apiAtenaAdminAppDetailsIdGet(requestParameters.id, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AtenaApiApiAtenaAdminAppDetailsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtenaApi
     */
    public apiAtenaAdminAppDetailsPut(requestParameters: AtenaApiApiAtenaAdminAppDetailsPutRequest = {}, options?: AxiosRequestConfig) {
        return AtenaApiFp(this.configuration).apiAtenaAdminAppDetailsPut(requestParameters.xAuth, requestParameters.inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AtenaApiApiAtenaAdminAppGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtenaApi
     */
    public apiAtenaAdminAppGet(requestParameters: AtenaApiApiAtenaAdminAppGetRequest = {}, options?: AxiosRequestConfig) {
        return AtenaApiFp(this.configuration).apiAtenaAdminAppGet(requestParameters.page, requestParameters.pageSize, requestParameters.role, requestParameters.name, requestParameters.source, requestParameters.notifications, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AtenaApiApiAtenaAdminAppPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtenaApi
     */
    public apiAtenaAdminAppPost(requestParameters: AtenaApiApiAtenaAdminAppPostRequest = {}, options?: AxiosRequestConfig) {
        return AtenaApiFp(this.configuration).apiAtenaAdminAppPost(requestParameters.xAuth, requestParameters.inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AtenaApiApiAtenaAdminAppPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtenaApi
     */
    public apiAtenaAdminAppPut(requestParameters: AtenaApiApiAtenaAdminAppPutRequest = {}, options?: AxiosRequestConfig) {
        return AtenaApiFp(this.configuration).apiAtenaAdminAppPut(requestParameters.xAuth, requestParameters.putRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtenaApi
     */
    public apiAtenaAdminRoleGet(options?: AxiosRequestConfig) {
        return AtenaApiFp(this.configuration).apiAtenaAdminRoleGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AtenaApiApiCoreAdministratorUsersOnlinePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtenaApi
     */
    public apiCoreAdministratorUsersOnlinePost(requestParameters: AtenaApiApiCoreAdministratorUsersOnlinePostRequest = {}, options?: AxiosRequestConfig) {
        return AtenaApiFp(this.configuration).apiCoreAdministratorUsersOnlinePost(requestParameters.xAuth, requestParameters.socketUsersViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AtenaApiAuthenticatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtenaApi
     */
    public authenticatePost(requestParameters: AtenaApiAuthenticatePostRequest = {}, options?: AxiosRequestConfig) {
        return AtenaApiFp(this.configuration).authenticatePost(requestParameters.xAuth, requestParameters.authViewModel, options).then((request) => request(this.axios, this.basePath));
    }
}
