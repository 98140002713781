import { RouteLocationRaw, Router } from 'vue-router'
import { RequiredAuthOptions } from './types'
import { useToast } from 'vue-toastification'
import { useAuth } from '@/auth/useAuth'

export function configureNavigationGuards(router: Router, options: RequiredAuthOptions) {
  const auth = useAuth()

  router.beforeEach(async (to): Promise<boolean | RouteLocationRaw> => {
    const toast = useToast()

    const userRole = (auth.user.roles && auth.user.roles[0]) || 'visitor'

    // Se for a rota de login
    if (to.name === options.loginRouteName) {
      if (auth.isAuthenticated) {
        console.log('🔒 Usuário já está autenticado. Redirecionando para rota de redirecionamento de login.')
        return { path: '/' }
      }
      console.log('🚪 Rota de login detectada, permitindo acesso.')
      return true
    }

    // Se a rota não for pública e o usuário não estiver autenticado
    if (!to.meta.public) {
      if (!auth.isAuthenticated) {
        console.log('❌ Acesso negado. Usuário não autenticado.')
        console.log('🔁 Redirecionando para a rota de login com redirecionamento após o login.')
        toast.info('Área restrita, faça login primeiro.', {
          timeout: 2000,
        })
        return { name: options.loginRouteName }
        // return { name: options.loginRouteName, query: { redirectTo: to.fullPath } }
      }

      const roleRouteMap: { [key: string]: string[] } = {
        admin: [
          '/home-admin',
          '/access',
          '/masks',
          '/applications',
          '/users',
          '/clinics',
          '/files',
          '/exams/details',
          '/',
        ],
        source: ['/home', '/files', '/exams/details', '/'],
        physician: ['/home-physician', '/'],
        analyst: ['/home-analyst', '/'],
      }

      // Função para verificar se a rota corresponde às rotas permitidas, incluindo rotas dinâmicas como `/exams/details?examId=14030`
      const isRouteAllowed = (route: string, allowedRoutes: string[]): boolean => {
        return allowedRoutes.some((allowedRoute) => route.startsWith(allowedRoute))
      }

      // Verifica se o papel do usuário está no mapeamento
      if (!roleRouteMap[userRole]) {
        console.log('⚠️ Acesso negado. Papel do usuário é inválido ou não permitido.')
        toast.error('Você não tem permissão para acessar esta página.', {
          timeout: 9000,
        })
        return { path: '/' }
      }

      // Verifica se a rota atual é permitida para o papel do usuário
      const currentRoute = window.location.pathname + window.location.search

      if (!isRouteAllowed(currentRoute, roleRouteMap[userRole])) {
        console.log('⚠️ Acesso negado. Rota não permitida para o papel do usuário.')
        toast.error('Você não tem permissão para acessar esta página.', {
          timeout: 9000,
        })
        return { path: '/' }
      }

      console.log('✅ Acesso permitido. Usuário com papel:', userRole)
    }
  })
}
